import * as React from 'react';
import {createContext, useContext, useState} from 'react';
import { useSnackbar } from '../SnackbarContext/SnackbarContext';
import useFetch from '../../Components/CommonUtility/useFetch';
import { IShow } from '../ShowContexts/Shows/IShow';
import { GridInputRowSelectionModel, GridRowSelectionModel, useGridApiRef } from '@mui/x-data-grid-premium';
import { useUser } from '../../Components/CommonUtility/UserContext';
import { AssignShowsToPayPeriod, UnassignShowsToPayPeriod } from './PayPeriodAPICalls';
import { useNavigate } from 'react-router-dom';


const SinglePayPeriodsContext = createContext<SinglePayPeriodsContextProps | null>(null);
interface SinglePayPeriodsContextProps {
    assignedShows: Array<IShow>,
    setAssignedShows: (shows: GridInputRowSelectionModel) => void,
    assignedShowsLoading: boolean,
    assignedShowsError: boolean,
    unassignedShows: Array<IShow>,
    setUnassignedShows: (shows: GridInputRowSelectionModel) => void,
    unassignedShowsLoading: boolean,
    unassignedShowsError: boolean,
    assignShow: () => void,
    unassignShow: () => void,
    selectedAssignedShows: GridRowSelectionModel,
    setSelectedAssignedShows: (shows: GridRowSelectionModel) => void,
    selectedUnassignedShows: GridRowSelectionModel,
    setSelectedUnassignedShows: (shows: GridRowSelectionModel) => void,
    assignedShowsGridApiRef: any,
    unassignedShowsGridApiRef: any,
    navigateToShow: (object: any) => void,
    currentTotalCommissions: CurrentCommissionProps,
    updating: boolean
}

type SinglePayPeriodsProviderProps = {
    children?: React.ReactNode;
    payPeriodId: string;
}
type CurrentCommissionProps = {[id: string]: {
    host: any, 
    totalCommission: number, 
    totalSales: number, 
    slotsCount: number, // total slots of a given show/person
    totalBreaks: number, // total breaks of a given show/person
    personalsCount: number, // total personals of a given show/person
    breaksTotalCommission: number,
    personalsTotalCommission: number
}}

export const SinglePayPeriodsProvider = ({children, payPeriodId} : SinglePayPeriodsProviderProps) => {
    const {newSnackbarMessage} = useSnackbar();
    const navigate = useNavigate();
    const {user} = useUser(); 
    const assignedShowsGridApiRef = useGridApiRef();
    const unassignedShowsGridApiRef = useGridApiRef();
    
    const [currentTotalCommissions, setTotalCommissions] = React.useState<CurrentCommissionProps>({});
    const [selectedAssignedShows, setSelectedAssignedShows] =
    React.useState<GridRowSelectionModel>([]);
    const [selectedUnassignedShows, setSelectedUnassignedShows] =
    React.useState<GridRowSelectionModel>([]);

    const [updating, setUpdating] = useState(false)
    const {data: assignedShows, setData: setAssignedShows, loading: assignedShowsLoading, error: assignedShowsError}: 
        { data: Array<IShow>, setData: any, loading: boolean, error: any }
        = useFetch("/PayPeriod/assigned/"+payPeriodId, true, [])

    const {data: unassignedShows, setData: setUnassignedShows, loading: unassignedShowsLoading, error: unassignedShowsError}:
        { data: Array<IShow>, setData: any, loading: boolean, error: any }
        = useFetch("/PayPeriod/unassigned", true, [])    
    
    React.useEffect(() => {
        if (assignedShows.length > 0) {
            let currentTotalCommission: CurrentCommissionProps = {}
                // Go through every sale
            const rows = assignedShows
            for (let i = 0; i < rows.length; i++) {
                const show = rows[i]
                for (let j = 0; j < show.showCommissionView.length; j++) {
                    const commission = show.showCommissionView[j]
                    currentTotalCommission = addToCommission(currentTotalCommission, commission)
                }
            }
            setTotalCommissions(currentTotalCommission)
        }
    }, [assignedShows])
    function addToCommission(currentTotalCommission: CurrentCommissionProps, commission: any) {
        const host = commission.hostDisplayName
        if (! (host in currentTotalCommission)) {
            currentTotalCommission[host] = {
                host: host,
                totalCommission: 0,
                totalSales: 0,
                slotsCount: 0,
                totalBreaks: 0,
                personalsCount: 0,
                breaksTotalCommission: 0,
                personalsTotalCommission: 0
            }
        }
        currentTotalCommission[host].totalCommission += commission.totalCommission
        currentTotalCommission[host].slotsCount += commission.slotsCount
        currentTotalCommission[host].totalBreaks += commission.totalBreaks
        currentTotalCommission[host].totalSales += commission.totalSales
        currentTotalCommission[host].personalsCount += commission.personalsCount
        currentTotalCommission[host].breaksTotalCommission += commission.breaksTotalCommission
        currentTotalCommission[host].personalsTotalCommission += commission.personalsTotalCommission
    
        return currentTotalCommission
    }

   async function assignShow() {
        if (!updating) { 
            setUpdating(true)
            const response = await AssignShowsToPayPeriod(selectedUnassignedShows, payPeriodId, user)
            
            if (response.status == 200) {
                const selectedRows = unassignedShowsGridApiRef.current.getSelectedRows()
                const rows = selectedRows.values()
                const rowsArray = Array.from(rows).map(value => value);
                setAssignedShows([...assignedShows, ...rowsArray])
                let currentAssigned = unassignedShows
                currentAssigned = currentAssigned.filter((show: IShow) => !rowsArray.includes(show))
                newSnackbarMessage("Successfully assigned shows", 'success')
                setUnassignedShows(currentAssigned)
            }
            setUpdating(false)
        }
    }
    
    async function unassignShow() {
        if (!updating) { 
            setUpdating(true)
            const response = await UnassignShowsToPayPeriod(selectedAssignedShows, user)
            
            if (response.status == 200) {
                const selectedRows = assignedShowsGridApiRef.current.getSelectedRows()
                const rows = selectedRows.values()
                const rowsArray = Array.from(rows).map(value => value);
                setUnassignedShows([...unassignedShows, ...rowsArray])
                let currentAssigned = assignedShows
                currentAssigned = currentAssigned.filter((show: IShow) => !rowsArray.includes(show))
                setAssignedShows(currentAssigned)
            }
            setUpdating(false)
        }
    }
    function navigateToShow(object: any) {
        navigate("/dashboard/show/"+object.publicId)
    }
    const contextValues: SinglePayPeriodsContextProps = {
        assignedShows,
        setAssignedShows,
        assignedShowsLoading,
        assignedShowsError,
        unassignedShows,
        setUnassignedShows,
        unassignedShowsLoading,
        unassignedShowsError,
        assignShow, unassignShow,
        selectedAssignedShows, setSelectedAssignedShows,
        selectedUnassignedShows, setSelectedUnassignedShows,
        assignedShowsGridApiRef,
        unassignedShowsGridApiRef,
        navigateToShow,
        currentTotalCommissions,
        updating
    }

    return (
        <SinglePayPeriodsContext.Provider value={contextValues}>
            {children}
        </SinglePayPeriodsContext.Provider>
        )
}

export function useSinglePayPeriods() {
    const context = useContext(SinglePayPeriodsContext);
    if (!context) {
        throw new Error("Invalid SinglePayPeriodContext, must be wrapped in a SinglePayPeriodProvider")
    }
    return context;
}