import React from 'react';
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid-pro';
import AddShowButton from './AddShowButton';
import AddPlatformButton from '../PlatformComponents/AddPlatformButton';
import { PlatformProvider } from '../../Contexts/ShowContexts/Platforms/PlatformContext';
import { ShowChannelProvider } from '../../Contexts/ShowContexts/ShowChannels/ShowChannelContext';
import AddShowChannelButton from '../ShowChannelComponents/AddShowChannelButton';
import { ShowSlotProvider } from '../../Contexts/ShowContexts/ShowSlots/ShowSlotContext';
import AddShowSlotButton from '../ShowSlotComponents/AddShowSlotButton';
import ShowHostQuicknavButton from '../ShowHostComponents/ShowHostQuicknavButton';

const ShowsDataGridToolbar: React.FC = ({}) => {
    return (
        <GridToolbarContainer>
            <GridToolbarQuickFilter />
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <GridToolbarDensitySelector />
            <AddShowButton />
            <PlatformProvider>
                <AddPlatformButton />
            </PlatformProvider>
            <ShowChannelProvider>
                <AddShowChannelButton />
            </ShowChannelProvider>
            <ShowSlotProvider>
                <AddShowSlotButton />
            </ShowSlotProvider>
            <ShowHostQuicknavButton />
        </GridToolbarContainer>
    );
};

export default ShowsDataGridToolbar;