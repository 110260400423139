import React from 'react';
import {createContext, useContext, useState, useEffect} from 'react';
import { useUser } from '../../../Components/CommonUtility/UserContext';
import { useSnackbar } from '../../SnackbarContext/SnackbarContext';
import { CreateShow, UpdateShow } from './ShowAPICalls';
import ShowModal from '../../../Components/ShowComponents/ShowModal';
import dayjs, { Dayjs } from 'dayjs';
import { IPlatform } from '../Platforms/IPlatform';
import { IShowChannel } from '../ShowChannels/IShowChannel';
import { IShowSlot } from '../ShowSlots/IShowSlot';
import { IShow } from './IShow';
import { ShowHostsProvider } from '../ShowHost/ShowHostsContext';
const ShowContext = createContext<ShowContextProps | null>(null)
interface ShowContextProps {
    title: string, setTitle: (name: string) => void,
    startTime: any, setStartTime: (date: any) => void,
    platform: any, setPlatform: (platform: any) => void, platformValid: () => boolean,
    channel: any, setChannel: (channel: any) => void,  channelValid: () => boolean,
    showSlot: any, setShowSlot: (showSlot: any) => void, showSlotValid: () => boolean,
    canCreate: boolean, setCanCreate: (name: boolean) => void,
    createShow: () => void,
    clearData: (name: boolean) => void,
    createShowModalOpen: boolean, 
    setCreateShowModal: (name: boolean) => void,
    closeShowModal: (name: boolean) => void,
    titleValid: () => boolean,
    startTimeValid: () => boolean,
    editMode: boolean, setEditMode: (name: boolean) => void,
    editShow: () => void,
    setEditFields(show: IShow): void,
    resetFields: () => void,
    setSelectedHosts: (host: ShowHost[]) => void, 
    selectedHosts: ShowHost[],
    editObject: IShow | null
}

type ShowProviderProps = {
    children?: React.ReactNode;
    baseShow?: IShow;
    addShow?: (show: IShow) => void;
    updateShow?: (show: IShow) => void;
}

export const ShowProvider = ({children, addShow, updateShow} : ShowProviderProps) => {
    const {newSnackbarMessage} = useSnackbar();

    const [editObject, setEditObject] = useState<IShow | null>(null); 
    const [title, setTitle] = useState(""); 
    const [startTime, setStartTimeState] = useState<Dayjs | null>(null);
    const [platform, setPlatformState] = useState<IPlatform | null>(null);
    const [channel, setChannelState] = useState<IShowChannel | null>(null);
    const [showSlot, setShowSlotState] = useState<IShowSlot | null>(null);
    const [selectedHosts, setSelectedHosts] = useState<ShowHost[]>([]);

    const [editMode, setEditMode] = useState(false);

    const [canCreate, setCanCreate] = useState(false);
    const [createShowModalOpen, setCreateShowModal] = useState(false);
    const {user} = useUser();

    async function createShow() {
        if (canCreate) {
            setCanCreate(false)
            try {
                const response = await CreateShow(title, startTime, showSlot, channel, user, selectedHosts);
                if (response.status == 200) {
                    const data = await response.json();
                    if (addShow) {
                      addShow(data);
                    }
                    newSnackbarMessage("Successfully created Show", 'success');
                    if (createShowModalOpen) {
                        setCreateShowModal(false);
                        clearData();
                    }
                } else {
                    newSnackbarMessage("Error creating show, see logs for detailed error message", 'error');
                }
            } catch (error) {
                console.error(error);
                newSnackbarMessage("Error creating show, connection invalid please try again later", 'error');
            }

            setCanCreate(true)
        }
    }
    async function editShow() {
        if (canCreate) {
            setCanCreate(false)
            try {
                const response = await UpdateShow(editObject?.publicId || "", title, startTime, showSlot, channel, user, selectedHosts);
                if (response.status == 200) {
                    const data = await response.json();
                    if (updateShow) {
                      updateShow(data);
                    }
                    newSnackbarMessage("Successfully updated Show", 'success');
                    if (createShowModalOpen) {
                        setCreateShowModal(false);
                        setEditMode(false);
                        clearData();
                    }
                } else {
                    newSnackbarMessage("Error updating show, see logs for detailed error message", 'error');
                }
            } catch (error) {
                console.error(error);
                newSnackbarMessage("Error updating show, connection invalid please try again later", 'error');
            }
            setCanCreate(true)
        }
    }
    function resetFields() {
        setEditFields(editObject as IShow)
    }
    function setEditFields(show: IShow) {
        if (show == null) return;
        setEditMode(true);
        setTitle(show.title);
        setStartTime(dayjs(show.startTime));
        setPlatform(show.showChannel.platform);
        setChannelState(show.showChannel);
        setShowSlotState(show.showSlot);
        setEditObject(show);
        setCreateShowModal(true);
        setSelectedHosts(show.showSelectedHosts.map(e => e.showHost))
    }

    function buildTitle() {
        let text = "";
        if (startTimeValid()) {
            text += startTime?.format("YYYYMMDD")
        }
        if (platformValid()) {
            text += ("_" +platform?.platformName)
        }
        if (channelValid()) {
            text += ("_" +channel?.channelName)
        }
        if (showSlotValid()) {
            text += ("_" +showSlot?.slotName)
        }
        setTitle(text)
    }
    // Update the canCreate state based on the input fields for validation reasons
    useEffect(() => {
        if (startTimeValid() && platformValid() && channelValid() && showSlotValid()){
            setCanCreate(true)
        } else {
            setCanCreate(false)
        }
        buildTitle()
    }, [startTime, platform, channel, showSlot])
    function setStartTime(newDate: Dayjs | null) {
        if (!newDate?.isSame(startTime, 'year') || !newDate?.isSame(startTime, 'month') || !newDate?.isSame(startTime, 'day')) setShowSlotState(null)
        setStartTimeState(newDate)
    }
    function setChannel(channel: IShowChannel | null) {
        setShowSlotState(null)
        setChannelState(channel)
    }

    function setPlatform(platform: IPlatform | null ) {
        setChannel(null);
        setPlatformState(platform)
    }
    // Function incase we need to implement more dependent functionality for showslots
    function setShowSlot(slot: IShowSlot) {
        setShowSlotState(slot)
    }

    function clearData() {
        setTitle("");
        setPlatform(null);
        setStartTime(dayjs((new Date).toString()));
        setSelectedHosts([]);
    }
    function closeShowModal() {
        setCreateShowModal(false);
        setCanCreate(false);
        setEditMode(false)
        clearData();
    }

    function titleValid() {
        return title.length > 0;
    }
    function startTimeValid() {
        return startTime != null;
    }
    function platformValid() {
        return platform != null 
    }
    function channelValid() {
        return channel != null && platformValid()
    }
    function showSlotValid() {
        return showSlot != null && channelValid() 
    }
    const contextValues = {
        title, setTitle, titleValid,
        startTime, setStartTime, startTimeValid,
        platform, setPlatform, platformValid,
        channel, setChannel, channelValid,
        showSlot, setShowSlot, showSlotValid,
        canCreate, setCanCreate,
        createShow,
        clearData,
        createShowModalOpen, setCreateShowModal, closeShowModal,
        editMode, setEditMode, editShow, setEditFields, resetFields,
        setSelectedHosts, selectedHosts,
        editObject
    }
    return (
        <ShowContext.Provider value={contextValues}>
            <ShowHostsProvider endpoint='/show/hosts/active'>
                <ShowModal />
                {children}
            </ShowHostsProvider>
        </ShowContext.Provider>
        )
}

export function useShow() {
    const context = useContext(ShowContext);
    if (!context) {
        throw new Error("Invalid ShowContext, must be wrapped in a ShowProvider")
    }
    return context;
}