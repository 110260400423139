import { Dayjs } from 'dayjs';
import FetchModule from '../../../Modules/FetchModule';
import { IShowSlot } from '../ShowSlots/IShowSlot';
import { IShowChannel } from '../ShowChannels/IShowChannel';
const fetchModule = new FetchModule();

export async function CreateShow(
    title: string, 
    startTime: Dayjs | null,
    showSlot: IShowSlot | null,
    showChannel: IShowChannel | null,
    user: any,
    selectedHosts: ShowHost[]
){
    const response = await fetchModule.postResponseBody("/show/new", "POST", 
    {
        UserId: user["id"],
        Body: {
            "title": title,
            "startTime": startTime?.toISOString(),
            "showSlotId": showSlot?.id,
            "showChannelId": showChannel?.id,
            "selectedHosts": selectedHosts.map((x) => x.id)
        }
    })
    return response    
}

export async function UpdateShow(
    publicId: string,
    title: string, 
    startTime: Dayjs | null,
    showSlot: IShowSlot | null,
    showChannel: IShowChannel | null,
    user: any,
    selectedHosts: ShowHost[]
) {
    const response = await fetchModule.postResponseBody("/show/update", "PUT", 
    {
        UserId: user["id"],
        Body: {
            "publicId": publicId,
            "title": title,
            "startTime": startTime?.toISOString(),
            "showSlotId": showSlot?.id,
            "showChannelId": showChannel?.id,
            "selectedHosts": selectedHosts.map((x) => x.id)
        }
    })
    return response
}
