import saveAs from "file-saver";
import FetchModule from '../Modules/FetchModule';
import api from '../Modules/APIModules';

const fetchData = new FetchModule();

export default function DownloadFile(url,fileName,body) {
  const token = String(fetchData.getCookie("jwtToken"));
  fetch(api.getLink() + url, {
      method: 'POST',
      headers: new Headers({
          'Authorization': "Bearer " + String(token),
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': "*",
      }),
      body: JSON.stringify(body)
  }).then(response => {
    if (response.status == 200)  {
        response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob );

        saveAs(url, fileName+".xlsx")
    
    });
}})
}

export function DownloadFileGet(url, fileName) {
    const token = String(fetchData.getCookie("jwtToken"));
    fetch(api.getLink() + url, {
        method: 'GET',
        headers: new Headers({
            'Authorization': "Bearer " + String(token),
            'Access-Control-Allow-Origin': "*",
        })
    }).then(response => {
      if (response.status == 200)  {
          response.blob().then(blob => {
          let url = window.URL.createObjectURL(blob );
  
          saveAs(url, fileName)
      });
  }})
  }