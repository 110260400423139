import { GridFilterModel } from "@mui/x-data-grid";
import BasicFilterInput from "./BasicFilterInput";
import FilterAutocomplete from "./FilterAutocomplete";

export class OperatorSetBuilder {
  private operatorSet: any[];
  private filterModel: GridFilterModel;
  private updateFilterModel: (field:string,operator:string,value:any) => any;

  constructor(filterModel: GridFilterModel, updateFilterModel: (field:string,operator:string,value:any) => any){
    this.operatorSet = []
    this.filterModel = filterModel;
    this.updateFilterModel = updateFilterModel;
  }

  addBasicOperator(field: string, displayName: string, operator:string,){
    this.operatorSet.push({
      label: displayName,
      value: operator,
      InputComponent: BasicFilterInput,
      InputComponentProps: {
        id: field+"-"+operator,
        field: field,
        operator: operator,
        filterModel: this.filterModel,
        updateFilterModel: this.updateFilterModel
      },
    })
  }

  addAutocompleteSingleValue(field: string, operator: string, displayName:string,options:any[], labelPath:string,
    updateFilterModel: (field:string,operator:string,value:any) => any,
    filterModel: any
  ){
    this.operatorSet.push({
      label: operator,
      value: displayName,
      InputComponent: FilterAutocomplete,
      InputComponentProps: {
        id: field+"-"+operator,
        field: field,
        operator: operator,
        label: displayName,
        options: options,
        isMultiple:false,
        labelPath: labelPath,
        updateFilterModel: updateFilterModel,
        filterModel: filterModel,
      },
    })
  }

  addAutocompleteMultipleValue(field: string, operator: string, displayName:string,options:any[], labelPath:string, 
    updateFilterModel: (field:string,operator:string,value:any) => any,
    filterModel: any
  ){
    this.operatorSet.push({
      label: operator,
      value: displayName,
      InputComponent: FilterAutocomplete,
      InputComponentProps: {
        id: field+"-"+operator,
        field: field,
        operator: operator,
        label: displayName,
        options: options,
        isMultiple:true,
        labelPath: labelPath,
        filterModel: filterModel,
        updateFilterModel: updateFilterModel
      },
    })
  }

  addNoValueFilter(operator: string, displayName:string){
    this.operatorSet.push({
      label: operator,
      value: displayName,
      InputComponent: null
    })
  }

  build(){
    return this.operatorSet;
  }



}