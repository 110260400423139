import { OperatorSetBuilder } from "./GenericServerSideComponents/OperatorsBuilder";

interface FilterProps {
  options: any[];
  field: any;
  updateFilterModel: (field:string,operator:string,value:any) => any;
  filterModel: any;
}

export const GradeFilter = ({ options, field, updateFilterModel, filterModel}: FilterProps) => {
  const operatorBuilder = new OperatorSetBuilder(filterModel, updateFilterModel);
  operatorBuilder.addAutocompleteSingleValue(field, "is", "=", options, "label", updateFilterModel, filterModel);
  operatorBuilder.addAutocompleteSingleValue(field, "is not", "!=", options, "label", updateFilterModel, filterModel);
  operatorBuilder.addAutocompleteSingleValue(field, "greater than", ">", options, "label", updateFilterModel, filterModel);
  operatorBuilder.addAutocompleteSingleValue(field, "less than", "<", options, "label", updateFilterModel, filterModel);
  operatorBuilder.addAutocompleteSingleValue(field, "greater than or equal to", ">=", options, "label", updateFilterModel, filterModel);
  operatorBuilder.addAutocompleteSingleValue(field, "less than or equal to", "<=", options, "label", updateFilterModel, filterModel);
  operatorBuilder.addBasicOperator(field, "contains", "contains");
  operatorBuilder.addBasicOperator(field, "does not contain","notContains")
  operatorBuilder.addNoValueFilter("is Raw", "isRaw");
  operatorBuilder.addNoValueFilter("is graded", "isGraded");
  operatorBuilder.addAutocompleteMultipleValue(field, "is any of", "isAnyOf", options, "label", updateFilterModel, filterModel);
  return operatorBuilder.build();
}