import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { AutocompleteHelper } from "../../../../Components/Autocomplete/ObjectExtractor";
import { matchSorter } from "match-sorter";
import "./Filter.css"; 

interface FilterAutocompleteProps {
  field: string;
  operator: string;
  options: any[];
  isMultiple?: boolean;
  labelPath?: string;
  filterModel: any;
  updateFilterModel: (field:string,operator:string,value:any) => any;
}

const FilterAutocomplete = ({field,operator,options,isMultiple,labelPath, filterModel, updateFilterModel}:FilterAutocompleteProps) => {
  const compareOperators = (item1: string, item2: string) => {
    const normalize = (str: string) => str.replace(/\s+/g, '').toLowerCase();
    return normalize(item1.toLowerCase()) === normalize(item2.toLowerCase());
  }

  

  const extractInitialValueFromFilter = ( field:string,operator:string) => {
    const filter = filterModel.items.find((item: { field: string; operator: string; }) => item.field === field && compareOperators(item.operator, operator));
    if(isMultiple){
      return Array.isArray(filter?.value) ? filter.value : [];
    }
    return filter?.value || [];
  };
  const [value, setValue] = useState(extractInitialValueFromFilter(field,operator) || []);

  const filterData = (options:any,valueToFilterOn:any)=>{
    if(labelPath !== ""){
      return matchSorter(options, valueToFilterOn, { keys: labelPath?.split(".") }).splice(0, 15);
    }
    return matchSorter(options, valueToFilterOn).splice(0, 15);
  }
  const handleFilterOptions = (options: string[], params: any) => {
    const { inputValue } = params;
    const filteredData = filterData(options,inputValue);
    return filteredData;
}
  return (
    <Autocomplete
      id={field + "-" + operator + "- Autocomplete"}
      options={options}
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        if(labelPath){
          const extractedVal = AutocompleteHelper.getLabelViaInfer(newValue,labelPath);
          updateFilterModel(field,operator,extractedVal);
        }
        else{
          updateFilterModel(field,operator,newValue);
        }
      }}
      multiple={isMultiple}
      renderInput={(params) => {
        return (
        <TextField
          {...params}
          id={field + "-" + operator + "- AutocompleteTextField"}
          variant="standard"
          className="filter-autocomplete"
          margin="dense"
        />
      )}}
      filterOptions={handleFilterOptions}
    />
  );
};

export default FilterAutocomplete;
