import { DataGridAutocompleteProps } from "./DatagridAutocompleteTypes";
import DataGridAutocompleteComponent from "./DatagridAutocompleteComponent";

export const DataGridAutocomplete = ({id,field,value,data,width,handleAddToData,typePublicId,labelPath,isMultiselect,propogateOnBlur,row,isGrouped}:DataGridAutocompleteProps)=>{

  return (
    <DataGridAutocompleteComponent
      id={id}
      field={field}
      value={value}
      data={data}
      width={width}
      handleAddToData={handleAddToData}
      typePublicId={typePublicId}
      labelPath={labelPath}    
      isMultiselect={isMultiselect}
      propogateOnBlur={propogateOnBlur}
      isGrouped={isGrouped}
      row={row}
      />
  )
}