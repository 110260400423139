import { OperatorSetBuilder } from "./GenericServerSideComponents/OperatorsBuilder";

interface FilterProps {
  options: any[];
  field: any;
  label?: string;
  updateFilterModel: (field:string,operator:string,value:any) => any;
  filterModel: any;
}

export const GenericStringFilter = ({ options, field,label, updateFilterModel, filterModel}: FilterProps) => {
  const operatorBuilder = new OperatorSetBuilder(filterModel, updateFilterModel);
  operatorBuilder.addAutocompleteSingleValue(field, "equals", "equals", options, label?label:"", updateFilterModel, filterModel);
  operatorBuilder.addBasicOperator(field, "contains", "contains");
  operatorBuilder.addBasicOperator(field, "does not contain","notContains")
  operatorBuilder.addBasicOperator(field, "starts with", "startsWith");
  operatorBuilder.addBasicOperator(field, "ends with", "endsWith");
  operatorBuilder.addNoValueFilter("is empty", "isEmpty");
  operatorBuilder.addNoValueFilter("is not empty", "isNotEmpty");
  operatorBuilder.addAutocompleteMultipleValue(field, "is any of", "isAnyOf", options, label?label:"", updateFilterModel, filterModel);
  return operatorBuilder.build();
}