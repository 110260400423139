import { DataGridPremium, GridActionsCellItem, GridColDef, GridRowId } from '@mui/x-data-grid-premium';
import React from 'react';
import dayjs from 'dayjs';
import { Typography } from '@mui/material';
import { usePayPeriods } from '../../Contexts/PayPeriodContexts/PayPeriodsContext';
import { ManageAccounts } from '@mui/icons-material';
import utc from 'dayjs/plugin/utc';
type PayPeriodsDataGridProps = {
    children?: React.ReactNode;
}
/* 
    PayPeriodsDatagrid component must be wrapped by a PayPeriodHostsProvider to work properly
*/
dayjs.extend(utc)

const PayPeriodsDataGrid = ({} : PayPeriodsDataGridProps)  => {
    const {
        PayPeriods, PayPeriodsError, PayPeriodsLoading, navigateToPayPeriod,
        paginationModel, setPaginationModel,
        setFilteringModel,
        setSortModel,
        fetching,
        totalRows
    } = usePayPeriods();
    
    const handleClick = (id: GridRowId, object: any) => () => {
        navigateToPayPeriod(object)
    };

    const columns: GridColDef[] = [
        { field: 'payoutDate', 
            headerName: 'Payout Date', 
            width: 200, 
            type: 'date',
            valueGetter: (value: any) => {
                const date = new Date(value)
                date.setDate(date.getDate() + 1)
                return date
            }
        },
        { field: 'days', 
            headerName: 'Pay Period', 
            width: 200,
            renderCell: (params) => {
                const day1 = dayjs(params.value[0]).format('YYYY-MM-DD')
                const day2 = dayjs(params.value[1]).format('YYYY-MM-DD')
                return <Typography>{day1} - {day2}</Typography>
            }
        },
        { field: 'totalSales', 
            headerName: 'Total Sales', 
            width: 100, 
            type: 'number',
            valueGetter: (params, row) => {
                if (row.payPeriodTotalsView == null) return 0
                return row.payPeriodTotalsView?.totalSales | 0
            },
            renderCell: (params => {
                return <Typography >${new Intl.NumberFormat().format(params.value.toFixed(2))}</Typography>
            })
        },
        { field: 'saleCount', 
            headerName: 'Sales', 
            width: 100, 
            type: 'number',
            valueGetter: (params, row) => {
                if (row.payPeriodTotalsView == null) return 0
                return row.payPeriodTotalsView?.totalSaleCount | 0
            },
            renderCell: (params => {
                return <Typography >{new Intl.NumberFormat().format(params.value.toFixed(2))}</Typography>
            })
        },
        { field: 'totalPersonals', 
            headerName: 'Personals', 
            width: 100,  
            type: 'number',
            valueGetter: (value, row) => {
                if (row.payPeriodTotalsView == null) return 0
                return row.payPeriodTotalsView?.totalPersonals | 0
            },
            renderCell: (params => {
                return <Typography >{new Intl.NumberFormat().format(params.value)}</Typography>
            })
        },
        { field: 'totalBreaks', 
            headerName: 'Breaks', 
            width: 100, 
            type: 'number',
            valueGetter: (value, row) => {
                if (row.payPeriodTotalsView == null) return 0
                return row.payPeriodTotalsView?.totalBreakSlots | 0
            },
            renderCell: (params => {
                return <Typography >{new Intl.NumberFormat().format(params.value)}</Typography>
            })
        },
        { field: 'totalGiveaways', 
            headerName: 'Giveaways', 
            width: 100, 
            type: 'number',
            valueGetter: (value, row) => {
                if (row.payPeriodTotalsView == null) return 0
                return row.payPeriodTotalsView?.totalGiveaways | 0
            },
            renderCell: (params => {
                return <Typography >{new Intl.NumberFormat().format(params.value)}</Typography>
            })
        },
        { field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id, row }) => {
                return [
                    <GridActionsCellItem
                        key={"PayPeriodEditClick-"+id}
                        icon={<ManageAccounts />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleClick(id, row)}
                        color="inherit"
                    />
                ];
              },
        }
    ];
    if (PayPeriodsLoading) return <div>Loading...</div>;
    if (PayPeriodsError) return <div>Error Loading</div>

    return(
        <>
           <DataGridPremium 
                rows={PayPeriods}
                columns={columns}
                initialState={{
                    pagination: {paginationModel: paginationModel},
                }}
                paginationMode="server"
                pagination
                sortingMode="server"
                pageSizeOptions={[25, 100, 500]}
                rowCount={totalRows}
                onPaginationModelChange={(newPaginationModel) => {
                    setPaginationModel(newPaginationModel)
                }}
                loading={fetching}
                filterMode="server"
                onFilterModelChange={(newFilterModel) => {
                    setFilteringModel(newFilterModel)
                }}
                onSortModelChange={(newSortModel) => {
                    setSortModel(newSortModel)
                }}
           />
        </>
    )
}
  
  
  export default PayPeriodsDataGrid;
  