import * as React from 'react';
import {createContext, useContext} from 'react';
import useFetch from '../../../Components/CommonUtility/useFetch';
import { IShow } from '../Shows/IShow';


const SingleShowContext = createContext<SingleShowContextProps | null>(null);
interface SingleShowContextProps {
    show: IShow | null,
    setShow: (show: any) => void,
    showLoading: boolean,
    showError: boolean,
    updateShow: (show: IShow) => void,
}

type SingleShowProviderProps = {
    children?: React.ReactNode;
    showPublicId: string;
}

export const SingleShowProvider = ({children, showPublicId} : SingleShowProviderProps) => {

    const {data: show, setData: setShow, loading: showLoading, error: showError}: 
        { data: IShow | null, setData: any, loading: boolean, error: any }
        = useFetch("/show/"+showPublicId, true, null)

    function updateShow(newShow: IShow) {
        if (show == null) return;
        const showToSet = show;
        showToSet.startTime = newShow.startTime
        showToSet.showChannel = newShow.showChannel
        showToSet.showSelectedHosts = newShow.showSelectedHosts
        showToSet.showSlot = newShow.showSlot
        showToSet.title = newShow.title
        setShow({...showToSet })

    }

    const contextValues = {
        show, setShow, showLoading, showError, updateShow
    }

    return (
        <SingleShowContext.Provider value={contextValues}>
            {children}
        </SingleShowContext.Provider>
        )
}

export function useSingleShow() {
    const context = useContext(SingleShowContext);
    if (!context) {
        throw new Error("Invalid ShowContext, must be wrapped in a ShowProvider")
    }
    return context;
}